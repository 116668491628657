<template>
    <div>
        <Header></Header>
        <div class="outer_box content_box">
            <el-tabs class="card" type="border-card" v-model="activeName">
                <el-tab-pane :label="$t('Create')" name="1">
                    <el-form :model="from" :rules="rules" ref="from" label-width="140px">
                        <el-form-item :label="$t('Movie title')" prop="title">
                            <el-input v-model="from.title" :placeholder="$t('Enter movie name')"></el-input>
                        </el-form-item>
                        <el-form-item :label="$t('Type')" prop="title">
                            <el-select v-model="from.type">
                                <el-option label="Movie" :value="0"></el-option>
                                <el-option label="MV" :value="1"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item :label="$t('Director name')" prop="director">
                            <el-input v-model="from.director" :placeholder="$t('Enter director name')"></el-input>
                        </el-form-item>
                        <el-form-item :label="from.type === 0 ? $t('Actor name') : $t('Singer name')" prop="actor">
                            <el-input v-model="from.actor" :placeholder="$t('Enter name')"></el-input>
                        </el-form-item>
                        <el-form-item :label="$t('Classify')" prop="classification">
                            <el-select v-if="from.type === 0" v-model="from.classification" :placeholder="$t('Classify')">
                                <el-option v-for="(item,index) in movieType" 
                                           :key=index 
                                           :label="item"
                                           :value="item">
                                </el-option>
                            </el-select>
                            <el-select  v-else v-model="from.classification" :placeholder="$t('Classify')">
                                <el-option v-for="(item,index) in musicType" 
                                           :key=index 
                                           :label="item"
                                           :value="item">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item :label="$t('Movie Artwork')" prop="title_page">
                            <el-upload
                                class="avatar-uploader"
                                :action="$center_url+'/upload/img'"
                                :show-file-list="false"
                                :on-success="uploadImgSuccess"
                                :before-upload="uploadBefor">
                                <img v-if="from.title_page" :src="$ipfs_url+from.title_page" class="avatar">
                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                            </el-upload>
                        </el-form-item>
                        <el-form-item :label="$t('intro')" prop="description">
                            <el-input v-model="from.description" type="textarea" :placeholder="$t('Please fill in the intro')"></el-input>
                        </el-form-item>
                        <el-form-item :label="$t('Upload Movie')">
                            <input type="file" 
                                   ref="uploadFile" 
                                   name="uploadVideo" 
                                   style="display:none" 
                                   @change="changeVideo" 
                                   id="uploadVideo">
                            <el-button icon="el-icon-upload2" @click="clickUpLoad">{{$t('Select Movie')}}</el-button>
                            <div>
                                <el-progress :percentage="progressPercentage"/>
                            </div>
                        </el-form-item>
                        <el-form-item :label="$t('Movie link')" prop="url">
                            <el-input v-model="from.url" type="textarea" disabled></el-input>
                        </el-form-item>
                        <el-button class="sub_btn" type="primary" @click="saveNFT('from')">{{$t('Create')}}</el-button>
                    </el-form>
                </el-tab-pane>  
                <el-tab-pane :label="$t('Works to be published')" name="2">
                    <el-empty v-if="waitList.length === 0" :description="$t('No record found')"></el-empty>
                    <div class="movie">
                        <ul class="movie_list">
                            <li class="movie_list_item" v-for="(item,index) in waitList" :key=index>
                                <div class="movie_list_item_box">
                                <div class="img_box">
                                    <img :src="$ipfs_url+item.title_page" alt="">
                                </div>
                                <div class="movie_name">
                                    {{item.title}}
                                </div>
                                <div class="movie_desc ellipsis">
                                    {{item.description}}
                                </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </el-tab-pane>
                <el-tab-pane :label="$t('published successfully')" name="3">
                    <el-empty v-if="issueList.length === 0" :description="$t('No record found')"></el-empty>
                    <div class="movie">
                        <ul class="movie_list">
                            <li class="movie_list_item" v-for="(item,index) in issueList" :key=index>
                                <div class="movie_list_item_box">
                                <div class="img_box">
                                    <img :src="$ipfs_url+item.title_page" alt="">
                                </div>
                                <div class="movie_name">
                                    {{item.title}}
                                </div>
                                <div class="movie_desc ellipsis">
                                    {{item.description}}
                                </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>
        <Footer></Footer>
        <el-dialog
            :title="$t('Notice')"
            :visible.sync="qrModal"
            width="30%"
            :before-close="handleClose">
            <div class="qr_modal_code">
                <span>NFT app {{$t('Sweep QR Code submitted')}}</span>
                <div class="qr_code" ref="qrCodeUrl"></div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="handleClose">OK</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import Header from "@/components/header.vue"
import Footer from "@/components/footer.vue"
import QRCode from 'qrcodejs2'
const AWS = require("aws-sdk");
export default {
    components:{ Header, Footer },
    data(){
        return{
            qrModal:false,
            activeName:"1",
            from:{
                title:"",
                director:"",
                type:0,
                actor:"",
                classification:"",
                title_page:"",
                description:"",
                url:"",
                pub_account_id:"",
            },
            waitList:[],
            issueList:[],
            movieType:[this.$t('All'),this.$t('Action'),this.$t('Romance'),this.$t('Science fiction'),this.$t('Comedy'),this.$t('Thriller'),this.$t('Drama'),this.$t('War'),this.$t('Microfilm')],
            musicType:[this.$t('All'),this.$t('Pop music'),this.$t('Romance'),this.$t('Rock'),this.$t('Folk'),this.$t('Animemia'),this.$t('Piano'),this.$t('Vocal concert'),this.$t('Cartoon')],
            rules:{
                title:[{ required: true, message: this.$t('required fields'), trigger: 'blur' }],
                director:[{ required: true, message: this.$t('required fields'), trigger: 'blur' }],
                actor:[{ required: true, message: this.$t('required fields'), trigger: 'blur' }],
                classification:[{ required: true, message: this.$t('required fields'), trigger: 'blur' }],
                description:[{ required: true, message: this.$t('required fields'), trigger: 'blur' }],
                url:[{ required: true, message: this.$t('required fields'), trigger: 'blur' }]
            },
            accessKeyId:"AKIAI7JMJY2GR7AX3ILQ",
            secretAccessKey:"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",
            region:"us-east-2",
            progressPercentage:0,
        }
    },
    methods:{
        init(){
            let local = window.localStorage.getItem("pcLocalAccount")
            if(local){
                let localJson = JSON.parse(local)
                this.from.pub_account_id = localJson.account_id
                this.getList()
            }
        },
        filterClassify(classify){
            let newClassify;
            switch(classify){
                case 'All': newClassify = "全部"; break;
                case 'Action': newClassify = "动作"; break;
                case 'Romance': newClassify = "爱情"; break;
                case 'Science fiction': newClassify = "科幻"; break;
                case 'Comedy': newClassify = "喜剧"; break;
                case 'Thriller': newClassify = "懸疑"; break;
                case 'Drama': newClassify = "剧情"; break;
                case 'War': newClassify = "战争"; break;
                case 'Microfilm': newClassify = "微电影"; break;
                case 'Pop music': newClassify = "流行音樂"; break;
                case 'Rock': newClassify = "搖滾"; break;
                case 'Folk': newClassify = "民族音樂"; break;
                case 'Animemia': newClassify = "純音樂"; break;
                case 'Piano': newClassify = "鋼琴曲"; break;
                case 'Vocal concert': newClassify = "演唱會"; break;
                case 'Cartoon': newClassify = "動畫"; break;
                default: newClassify = classify;
            }
            return newClassify;
        },
        getList(){
            this.$fetch(this.$center_url+`/v1/movice/list?state=10&account_id=${this.from.pub_account_id}&page=1&num=40`).then((res)=>{
                this.waitList = res.data.data;
            })
            this.$fetch(this.$center_url+`/v1/movice/list?state=15&account_id=${this.from.pub_account_id}&page=1&num=40`).then((res)=>{
                this.issueList = res.data.data;
            })
        },
        createQR(text){
            let qrcodeText = {
                type:1,
                data:text
            }
            new QRCode(this.$refs.qrCodeUrl, {
                text: JSON.stringify(qrcodeText),
                width: 200,
                height: 200,
                colorDark: '#000000',
                colorLight: '#ffffff',
                correctLevel: QRCode.CorrectLevel.H
            })
        },
        saveNFT(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.from.type = this.filterClassify(this.from.type);
                    this.$post(this.$center_url+"/v1/movice/create",this.from).then((res)=>{
                        if(res.code === "ok"){
                            this.qrModal = true
                            this.getList()
                            this.$nextTick(() => {
                                this.createQR(res.data)
                            })
                        }else{
                            this.$message(this.$t('Submission failed'));
                        }
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        handleClose(){
            this.$refs.qrCodeUrl.innerHTML = ''
            this.qrModal = false
        },
        uploadImgSuccess(res){
            this.from.title_page = res.data;
        },
        uploadBefor(file){
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isLt2M) {
                this.$message.error('上传头像图片大小不能超过 2MB!');
            }
            return isLt2M;
        },
        changeVideo(e){
            let file =  e.target.files[0];
            this.progressPercentage = 0;
            this.upLoadFile(file).on("httpUploadProgress",(progress)=>{ // 上传回调
                let progressPercentage = Math.round(progress.loaded / progress.total * 100); // 百分比
                this.progressPercentage = progressPercentage;
            });
        },
        upLoadFile(file){
            var bucket = new AWS.S3({
                accessKeyId: this.accessKeyId,
                secretAccessKey: this.secretAccessKey,
                region: this.region,
            });
            const params = {
                Bucket: "abqqfilm",
                ACL: 'public-read',
                Key: "upload/film/" + this.getToday() + "/" + file.name,
                Body: file,
                ContentType: file.type,
            };
            return bucket.upload(params, (err, data)=> {
                if (err) {
                    this.$Message.error(this.$t('Submission failed'))
                    return false;
                }
                this.from.url = data.Location;
                return true;
            });
        },
        clickUpLoad(){
            this.$refs.uploadFile.click();
        },
        // 獲得今日日期
        getToday() {
            var date = new Date();
            var nowMonth = date.getMonth() + 1;
            var strDate = date.getDate();
            var seperator = "/";
            if (nowMonth >= 1 && nowMonth <= 9) { nowMonth = "0" + nowMonth; }
            if (strDate >= 0 && strDate <= 9) { strDate = "0" + strDate; }
            return date.getFullYear() + seperator + nowMonth + seperator + strDate;
        } 
    },
    created(){
        this.init()
    }
}
</script>
<style lang="less" scoped>
@import url("./index.less");
</style>
